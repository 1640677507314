import React from "react"
import { styled } from "../components/mediaCSS"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContentHolder = styled.div``
const Content = styled.div``

export default () => {
  return (
    <Layout>
      <SEO title="About Us" />
      <ContentHolder>
        <Content>
          <p>
            Privacy Policy This privacy policy has been compiled to better serve
            those who are concerned with how their &#39;Personally identifiable
            information&#39; (PII) is being used online. PII, as used in US
            privacy law and information security, is information that can be
            used on its own or with other information to identify, contact, or
            locate a single person, or to identify an individual in context.
            Please read our privacy policy carefully to get a clear
            understanding of how we collect, use, protect or otherwise handle
            your Personally Identifiable Information in accordance with our
            website.
          </p>
          <p>
            What personal information do we collect from the people that visit
            our blog, website or app?
          </p>
          <p>
            When ordering or registering on our site, as appropriate, you may be
            asked to enter your name, email address, phone number or other
            details to help you with your experience.
          </p>
          <p>When do we collect information?</p>
          <p>
            We collect information from you when you register on our site, place
            an order or enter information on our site.
          </p>
          <p>
            Provide us with feedback on our products or services How do we use
            your information?
          </p>
          <p>
            We may use the information we collect from you when you register,
            make a purchase, sign up for our newsletter, respond to a survey or
            marketing communication, surf the website, or use certain other site
            features in the following ways: - To personalize user&#39;s
            experience and to allow us to deliver the type of content and
            product offerings in which you are most interested. - To improve our
            website in order to better serve you. - To allow us to better
            service you in responding to your customer service requests. - To
            administer a contest, promotion, survey or other site feature. - To
            quickly process your transactions. - To ask for ratings and reviews
            of services or products - To follow up with them after
            correspondence (live chat, email or phone inquiries)
          </p>
          <p>How do we protect visitor information?</p>
          <p>
            We do not use vulnerability scanning and/or scanning to PCI
            standards. We only provide articles and information. We never ask
            for credit card numbers. We use regular Malware Scanning.
          </p>
          <p>
            Your personal information is contained behind secured networks and
            is only accessible by a limited number of persons who have special
            access rights to such systems, and are required to keep the
            information confidential. In addition, all sensitive/credit
            information you supply is encrypted via Secure Socket Layer (SSL)
            technology.
          </p>
          <p>
            We implement a variety of security measures when a user places an
            order enters, submits, or accesses their information to maintain the
            safety of your personal information.
          </p>
          <p>
            All transactions are processed through a gateway provider and are
            not stored or processed on our servers.
          </p>
          <p>Do we use &#39;cookies&#39;?</p>
          <p>
            Yes. Cookies are small files that a site or its service provider
            transfers to your computer&#39;s hard drive through your Web browser
            (if you allow) that enables the site&#39;s or service provider&#39;s
            systems to recognize your browser and capture and remember certain
            information. For instance, we use cookies to help us remember and
            process the items in your shopping cart. They are also used to help
            us understand your preferences based on previous or current site
            activity, which enables us to provide you with improved services. We
            also use cookies to help us compile aggregate data about site
            traffic and site interaction so that we can offer better site
            experiences and tools in the future.
          </p>
          <p>
            We use cookies to: * Help remember and process the items in the
            shopping cart. * Understand and save user&#39;s preferences for
            future visits.
          </p>
          <p>
            You can choose to have your computer warn you each time a cookie is
            being sent, or you can choose to turn off all cookies. You do this
            through your browser (like Internet Explorer) settings. Each browser
            is a little different, so look at your browser&#39;s Help menu to
            learn the correct way to modify your cookies.
          </p>
          <p>If users disable cookies in their browser:</p>
          <p>
            If you disable cookies off, some features will be disabled It will
            turn off some of the features that make your site experience more
            efficient and some of our services will not function properly.
          </p>
          <p>However, you can still place orders over the telephone .</p>
          <p>Third-party disclosure</p>
          <p>
            We do not sell, trade, or otherwise transfer to outside parties your
            personally identifiable information unless we provide users with
            advance notice. This does not include website hosting partners and
            other parties who assist us in operating our website, conducting our
            business, or serving our users, so long as those parties agree to
            keep this information confidential. We may also release information
            when it&#39;s release is appropriate to comply with the law, enforce
            our site policies, or protect ours or others&#39; rights, property
            or safety.{" "}
          </p>
          <p>
            However, non-personally identifiable visitor information may be
            provided to other parties for marketing, advertising, or other uses.
          </p>
          <p>Third-party links</p>
          <p>
            Occasionally, at our discretion, we may include or offer third-party
            products or services on our website. These third-party sites have
            separate and independent privacy policies. We therefore have no
            responsibility or liability for the content and activities of these
            linked sites. Nonetheless, we seek to protect the integrity of our
            site and welcome any feedback about these sites.
          </p>
          <p>Google</p>
          <p>
            Google&#39;s advertising requirements can be summed up by
            Google&#39;s Advertising Principles. They are put in place to
            provide a positive experience for users.{" "}
            <a href="https://support.google.com/adwordspolicy/answer/1316548?hl=en">
              https://support.google.com/adwordspolicy/answer/1316548?hl=en
            </a>{" "}
          </p>
          <p>We use Google AdSense Advertising on our website.</p>
          <p>
            Google, as a third-party vendor, uses cookies to serve ads on our
            site. Google&#39;s use of the DART cookie enables it to serve ads to
            our users based on previous visits to our site and other sites on
            the Internet. Users may opt-out of the use of the DART cookie by
            visiting the Google Ad and Content Network privacy policy.
          </p>
          <p>
            We have implemented the following: â€¢ Demographics and Interests
            Reporting
          </p>
          <p>
            We along with third-party vendors, such as Google use first-party
            cookies (such as the Google Analytics cookies) and third-party
            cookies (such as the DoubleClick cookie) or other third-party
            identifiers together to compile data regarding user interactions
            with ad impressions and other ad service functions as they relate to
            our website.
          </p>
          <p>
            Opting out: Users can set preferences for how Google advertises to
            you using the Google Ad Settings page. Alternatively, you can opt
            out by visiting the Network Advertising initiative opt out page or
            permanently using the Google Analytics Opt Out Browser add on.
          </p>
          <p>COPPA (Children Online Privacy Protection Act)</p>
          <p>
            When it comes to the collection of personal information from
            children under 13, the Children&#39;s Online Privacy Protection Act
            (COPPA) puts parents in control. The Federal Trade Commission, the
            nation&#39;s consumer protection agency, enforces the COPPA Rule,
            which spells out what operators of websites and online services must
            do to protect children&#39;s privacy and safety online.
          </p>
          <p>We do not specifically market to children under 13.</p>
          <p>CAN SPAM Act</p>
          <p>
            The CAN-SPAM Act is a law that sets the rules for commercial email,
            establishes requirements for commercial messages, gives recipients
            the right to have emails stopped from being sent to them, and spells
            out tough penalties for violations.
          </p>
          <p>
            We collect your email address in order to: - Send information,
            respond to inquiries, and/or other requests or questions. - Process
            orders and to send information and updates pertaining to orders. -
            We may also send you additional information related to your product
            and/or service.
          </p>
          <p>To be in accordance with CANSPAM we agree to the following:</p>
          <p>
            If at any time you would like to unsubscribe from receiving future
            emails, you can email us at and we will promptly remove you from ALL
            correspondence.
          </p>
          <p>Contacting Us</p>
          <p>
            If there are any questions regarding this privacy policy you may
            contact us using the information below.
          </p>
          <p>Mama&#39;s Pizza 817-870-2571</p>
          <p>Last Edited on 2016-05-18</p>
        </Content>
      </ContentHolder>
    </Layout>
  )
}
